import "../../css/components/admin-panel/project-approval.css";

import {ColumnsType} from "antd/es/table";
import {useEffect, useState} from "react";
import {AutoComplete, Button, Input, message, Popconfirm, Table} from "antd";
import {CheckOutlined, CloseOutlined, QuestionCircleOutlined, SearchOutlined} from "@ant-design/icons";
import ReactQuill from "react-quill";
import Paragraph from "antd/es/typography/Paragraph";
import GetFile from "../common/files/get-file";
import ShowAvatar from "../common/avatar-image/avatar";
import instance from "../../api/configuration/configure";
import GetImage from "../common/files/get-image";
import {useAdminPanelStore} from "../../stores/adminPanelStore";
import debounce from "debounce";

const columns: ColumnsType<any> = [
    {
        title: "Назва проєкту",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "Факультет",
        dataIndex: "categoryName",
        key: "categoryName",
    },
    {
        title: "Дата створення",
        dataIndex: "dateCreated",
        key: "dateCreated",
    },
];

const ProjectsApproval = () => {
    const [projectName, setProjectName] = useState<string>("");
    const {approvalProjects, setApprovalProjects} = useAdminPanelStore();

    useEffect(() => {
        if (approvalProjects == null) {
            instance.get(`api/verify?page=1&projectName=${projectName}`).then((response: any) => {
                setApprovalProjects(response.data);
            }).catch(() => {
                message?.error("Помилка підгрузки проєктів");
            });
        }
    }, []);

    useEffect(() => {
        debouncedSearchProjects();
    }, [projectName]);

    const handleTableChange = async (pagination: any) => {
        instance.get(`api/verify?page=${pagination.current}&projectName=${projectName}`).then((response: any) => {
            setApprovalProjects(response.data);
        }).catch(() => {
            message?.error("Помилка підгрузки проєктів");
        });
    };

    const debouncedSearchProjects = debounce(async () => {
        instance.get(`api/verify?page=1&projectName=${projectName}`).then((response: any) => {
            setApprovalProjects(response.data);
        }).catch(() => {
            message?.error("Помилка підгрузки проєктів");
        });
    }, 800);

    const handleProjectNameOnChange = (value: string) => {
        setProjectName(value);
    }

    return (
        <section className="approval-project-wrapper">
            <h2 className="project-approvement-title">Затвердження проєктів</h2>
            <AutoComplete
                onChange={handleProjectNameOnChange}
                className="project-approvement-table-title-input"
                allowClear
            >
                <Input prefix={<SearchOutlined/>} placeholder="Введіть назву наукової роботи"/>
            </AutoComplete>
            <Table
                size="small"
                columns={columns}
                dataSource={approvalProjects.items}
                rowKey="id"
                onChange={handleTableChange}
                className="project-approvement-table"
                expandable={{
                    expandedRowRender: (record) => (
                        <div className="project-approvement-table-expandable">
                            <div className="project-approvement-table-media">
                                <GetImage record={record.coverImageName}/>
                                <div className="project-approvement-table-approve">
                                    <Popconfirm
                                        title="Затвердження проєкту"
                                        description="Чи ви впевнені щодо затвердження цього проєкту?"
                                        onConfirm={() => {
                                            instance
                                                .put("api/verify/verify?id=" + record.id)
                                                .then((response: any) => {
                                                    instance.get(`api/verify?page=1&projectName=${projectName}`).then((response: any) => {
                                                        setApprovalProjects(response.data);
                                                    }).catch(() => {
                                                        message?.error("Помилка підгрузки проєктів");
                                                    });

                                                    message?.success(response.data.message);
                                                })
                                                .catch((error: any) => {
                                                    message?.error(error?.response.message);
                                                });
                                        }}
                                        icon={<QuestionCircleOutlined style={{color: "green"}}/>}
                                    >
                                        <Button type="dashed">
                                            <CheckOutlined style={{color: "green"}}/> Прийняти
                                        </Button>
                                    </Popconfirm>
                                    <Popconfirm
                                        title="Затвердження проєкту"
                                        description="Чи ви впевнені щодо відхилення цього проєкту?"
                                        onConfirm={() => {
                                            instance
                                                .put("api/verify/deny?id=" + record.id)
                                                .then((response: any) => {
                                                    instance.get(`api/verify?page=1&projectName=${projectName}`).then((response: any) => {
                                                        setApprovalProjects(response.data);
                                                    }).catch(() => {
                                                        message?.error("Помилка підгрузки проєктів");
                                                    });

                                                    message.success(response.data.message);
                                                })
                                                .catch((error: any) => {
                                                    message.error(error?.response.data.message);
                                                });
                                        }}
                                        icon={<QuestionCircleOutlined style={{color: "red"}}/>}
                                    >
                                        <Button type="dashed">
                                            <CloseOutlined style={{color: "red"}}/> Відхилити
                                        </Button>
                                    </Popconfirm>
                                </div>
                                <div className="project-approvement-table-file">
                                    <GetFile record={record}/>
                                </div>
                            </div>
                            <div className="project-approvement-table-expandable-content">
                                <div className="project-approvement-table-expandable-full-name">
                                    <ShowAvatar value={record.avatarImage}/>
                                    <Paragraph>{record.fullName}</Paragraph>
                                </div>
                                <Paragraph className="project-approvement-table-expandable-key-words">
                                    Ключові слова: {record.tags.map((x: any) => x + ", ")}..
                                </Paragraph>
                                <div className="project-approvement-table-expandable-description">
                                    <ReactQuill
                                        value={record.description}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                </div>
                            </div>
                        </div>
                    ),
                }}
                pagination={{
                    current: approvalProjects.pageIndex,
                    pageSize: approvalProjects.pageSize,
                    total: approvalProjects.totalCount,
                    pageSizeOptions: [],
                }}
                scroll={{x: 300}}
                bordered
            />
        </section>
    );
}

export default ProjectsApproval;
