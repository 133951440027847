import "../../../css/common/googleAuth.css";
import googleLogo from '../../../assets/logos/google-logo.svg';

import {useGoogleLogin} from '@react-oauth/google';
import {Button, message} from 'antd';

export interface IGoogleAuth {
    handleGoogleSuccess(response: any): void;

    text: string;
    className?: string;
    loading?: boolean;
}

export interface IAuthProvider {
    provider: string;
    token: string;
}

const GoogleAuth = ({handleGoogleSuccess, text, className, loading}: IGoogleAuth) => {
    const auth = useGoogleLogin({
        onSuccess: handleGoogleSuccess,
        onError: () => {
            message.error("Авторизація через Google не була успішною з певних причин.");
        },
        flow: 'implicit',
    });

    return (
        <Button
            type='primary'
            onClick={() => auth()}
            className={"google-button " + className}
            loading={loading}
            disabled={loading}
            ghost
        >
            <img src={googleLogo} className="google-logo" alt="Google logotype"/>
            <p>{text}</p>
        </Button>
    );
};

export default GoogleAuth;
