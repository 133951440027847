import {create} from "zustand/react";

export interface Project {
    id: string;
    name: string;
    author: string;
    coverImage: string;
}

export interface ProjectsList {
    items: Array<Project>;
    totalCount: number;
    pageIndex: number;
    pageSize: number;
    totalPages: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
}

export interface ProjectDetails {
    id: string;
    name: string;
    fileName: string;
    coverImageName: string;
    description: string;
    categoryName: string;
    author: string;
    authorAvatar: string;
    dateCreated: string;
    dateEdited: string;
    type: number;
    tags: Array<string>;
    isVerified: boolean | null;
}

export interface ProjectUpdate {
    id: string;
    name: string;
    description: string;
    categoryName: string;
    type: number;
    tags: Array<string>;
}

type ContentStore = {
    data: ProjectsList;
    setData: (data: ProjectsList) => void;
    details: ProjectDetails;
    setDetails: (details: ProjectDetails) => void;
    updateOpen: boolean;
    setUpdateOpen: (open: boolean) => void;
    updateData: ProjectUpdate;
    setUpdateData: (updateData: ProjectUpdate) => void;
    page: number;
    setPage: (page: number) => void;
}

export const useContentStore = create<ContentStore>()(
    (set) => ({
        data: {
            items: [] as Array<Project>,
            totalCount: 0,
            pageIndex: 0,
            pageSize: 0,
            totalPages: 0,
            hasPreviousPage: false,
            hasNextPage: false
        },
        setData: (data: ProjectsList) => set((state) => ({data: data})),
        details: {
            id: "",
            name: "",
            fileName: "",
            coverImageName: "",
            description: "",
            categoryName: "",
            author: "",
            authorAvatar: "",
            dateCreated: "",
            dateEdited: "",
            type: 0,
            tags: [],
            isVerified: null
        },
        setDetails: (details: ProjectDetails) => set(() => ({details: details})),
        updateOpen: false,
        setUpdateOpen: (isOpen: boolean) => set(() => ({updateOpen: isOpen})),
        updateData: {
            id: "",
            name: "",
            description: "",
            categoryName: "",
            type: 0,
            tags: []
        } as ProjectUpdate,
        setUpdateData: (updateData: ProjectUpdate) => set(() => ({updateData: updateData})),
        page: 1,
        setPage: (page: number) => set(() => ({page: page})),
    })
);
