import '../../../css/common/getImage.css';

import {Image} from "antd";

const GetImage = ({record}: any) => {
    return (
        <div className="get-image-wrapper">
            <Image
                src={`${process.env.REACT_APP_SERVER_URL}storage/image?filename=${record}`}
                className="get-image-image"
                alt="Cover Image"
            />
        </div>
    );
};

export default GetImage;
