import './css/global/index.css';

import App from './App';

import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import {ConfigProvider} from "antd";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <ConfigProvider
        theme={{
            token: {
                colorPrimary: '#112D4E',
                borderRadius: 3,
                colorLink: '#3F72AF',
                colorLinkHover: '#112D4E',
                colorLinkActive: '#112D4E',
                colorBgContainer: '#DBE2EF',
                colorBgBase: '#F9F7F7',
                colorText: '#112D4E',
                colorTextQuaternary: '#575353',
                colorBorder: '#3F72AF',
                colorError: '#FF7777',
                colorSuccess: '#112D4E',
                colorInfo: '#112D4E',
                colorWarning: '#FFB84C',
                colorTextSecondary: '#6C757D',
                colorTextTertiary: '#ADB5BD',
                colorIcon: '#3F72AF',
                colorIconHover: '#112D4E',
                colorTextDisabled: '#B4B4B4',
                colorBgMask: '#00000080',
                colorBgSpotlight: '#3F72AF',
                colorBgLayout: '#F4F6F9',
                colorWarningBorder: '#FFB84C',
                colorPrimaryBorder: '#112D4E',
                colorBorderSecondary: '#3F72AF',
            },
        }}
    >
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </ConfigProvider>
);
