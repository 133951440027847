import '../../../css/components/auth/passwordRecoveryConfirmation.css';

import {EyeInvisibleOutlined, EyeTwoTone, LockOutlined,} from "@ant-design/icons";
import {Button, Form, Input, message} from "antd";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useEffect} from 'react';
import axios from "axios";

const PasswordRecoveryConfirmation = () => {
    const {executeRecaptcha} = useGoogleReCaptcha();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        document.title = "Відновлення паролю | РФК НУБіП";
    }, []);

    const onFinish = async (values: any) => {
        try {
            if (!executeRecaptcha) {
                return;
            }

            const reCaptchaToken = await executeRecaptcha();
            const response = await axios.post(
                process.env.REACT_APP_SERVER_URL + "api/auth/confirm-password",
                {
                    email: searchParams.get("email"),
                    newPassword: values.password,
                    token: searchParams.get("token"),
                    reCaptchaToken: reCaptchaToken,
                }
            );

            setTimeout(() => {
                navigate("/password-recovery-confirm-result");
            }, 3500);

            message.success(response?.data.message);
        } catch (error: any) {
            message.error(error?.message);
        }
    };

    return (
        <>
            <h1 className="auth-header">Відновити пароль</h1>
            <h2 className="college-header">Рівненський фаховий коледж НУБіП України</h2>
            <p className="no-profile-text">Повернутися назад? <Link
                className="auth-link"
                to="/login">Вхід</Link></p>
            <Form
                initialValues={{remember: true}}
                autoComplete="on"
                onFinish={onFinish}
            >
                <Form.Item
                    name="password"
                    rules={[
                        { required: true, message: "Введіть ваш пароль!" },
                        {
                            pattern: /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/,
                            message: "Пароль має складатися з 8-32 символів, великої літери, цифри та\n спеціального символу (@$!%*?&)!",
                        },
                        {
                            pattern: /^(?=.*[^A-Za-z\d\s])/, // Ensures at least one non-alphanumeric character
                            message: "Пароль має містити принаймні один неалфавітний символ!",
                        },
                    ]}
                    className="form-item-recovery-password-confirmation"
                    hasFeedback
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon"/>}
                        type="password"
                        className="form-input-recovery-password-confirmation"
                        placeholder="Новий пароль"
                        iconRender={(visible) =>
                            visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>
                        }
                    />
                </Form.Item>
                <Form.Item
                    name="confirmPassword"
                    dependencies={["password"]}
                    className="form-item-recovery-password-confirmation"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: "Будь ласка введіть ваш новий пароль!",
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue("password") === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error("Ваші паролі не співпадають!")
                                );
                            },
                        }),
                    ]}
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon"/>}
                        type="password"
                        className="form-input-recovery-password-confirmation"
                        placeholder="Новий пароль повторно"
                        iconRender={(visible) =>
                            visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>
                        }
                    />
                </Form.Item>
                <Button type="primary" className="form-btn-submit-recover-password-confirmation" htmlType="submit">
                    Відновити
                </Button>
            </Form>
        </>
    );
};

export default PasswordRecoveryConfirmation;
