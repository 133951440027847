import axios, {AxiosError} from "axios";
import {refreshToken} from "../refreshToken";
import {useAuthStore, UserType} from "../../stores/authStore";
import {message} from "antd";
import {useNavigate} from "react-router-dom";
import {getNavigateFunction} from "../../components/common/navigateHelper";

type RequestQueueItem = {
    config: any;
    resolve: (value?: any) => void;
    reject: (reason?: any) => void;
};

let isRefreshing = false;
let failedQueue: RequestQueueItem[] = [];

const instance = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: {"Content-Type": "application/json"},
});

const processQueue = (error: AxiosError | null, token: string | null = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(instance(prom.config));
        }
    });

    failedQueue = [];

    if (token) {
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
};

instance.interceptors.request.use(
    (configuration: any) => {
        const accessToken = localStorage.getItem("token");

        if (accessToken && configuration.headers) {
            configuration.headers.Authorization = `Bearer ${accessToken}`;
        }

        return configuration;
    },
    (error) => Promise.reject(error)
);

instance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (!error.response) {
            const { logout } = useAuthStore.getState();
            logout();

            getNavigateFunction()?.('/login');

            message.error("Звязок з сервером втрачено");
            return Promise.reject(error);
        }

        if (
            (error.response?.status === 404 || error.response?.status === 500) &&
            originalRequest.url === `${process.env.REACT_APP_SERVER_URL}api/auth/refresh-token`
        ) {
            axios.post(
                `${process.env.REACT_APP_SERVER_URL}api/auth/revoke-token`,
                {token: localStorage.getItem("refreshToken")}
            ).then((response) => {
                message.success(response.data.message);
            }).catch(() => {
                getNavigateFunction()?.('/login');
            });

            const { logout } = useAuthStore.getState();
            logout();

            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("avatarImage");

            return Promise.reject(error);
        }

        if (error.response?.status === 401 && !originalRequest._retry) {
            if (!isRefreshing) {
                isRefreshing = true;
                const localRefreshToken = localStorage.getItem("refreshToken");

                try {
                    if (localRefreshToken) {
                        const {
                            token: newAccessToken,
                            refreshToken: newRefreshToken
                        } = await refreshToken(localRefreshToken);

                        localStorage.setItem("token", newAccessToken);
                        localStorage.setItem("refreshToken", newRefreshToken);

                        processQueue(null, newAccessToken);
                    } else {
                        const { logout } = useAuthStore.getState();
                        logout();

                        localStorage.removeItem("token");
                        localStorage.removeItem("refreshToken");
                        localStorage.removeItem("avatarImage");

                        getNavigateFunction()?.('/login');

                        message.error("Дані авторизації відсутні");
                        throw new Error("No refresh token available.");
                    }
                } catch (internalError) {
                    processQueue(internalError as AxiosError, null);
                    return Promise.reject(internalError);
                } finally {
                    isRefreshing = false;
                }
            }

            return new Promise((resolve, reject) => {
                originalRequest._retry = true;
                failedQueue.push({resolve, reject, config: originalRequest});
            });
        }

        return Promise.reject(error);
    }
);

export default instance;
