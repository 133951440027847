import {Result} from "antd";

const EmailInfoSend = () => {
    return (
        <Result
            status="info"
            title="Лист надіслано"
            subTitle="Будь ласка підтвердіть вашу поштову скриньку за наданим до неї посиланням!"
        />
    );
};

export default EmailInfoSend;