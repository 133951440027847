import '../../../css/components/auth/login.css';
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {Button, Checkbox, Form, Input, message} from "antd";
import {EyeInvisibleOutlined, EyeTwoTone, LockOutlined, UserOutlined} from "@ant-design/icons";
import GoogleAuth from "../../common/google-auth/google-auth";
import axios from "axios";
import {jwtDecode} from "jwt-decode";
import {useAuthStore, UserType} from "../../../stores/authStore";

export interface Login {
    email: string,
    password: string,
    isRemember: boolean;
}

export interface CreateLogin {
    email: string,
    password: string,
    reCaptchaToken: string,
}

const Login = () => {
    const {executeRecaptcha} = useGoogleReCaptcha();
    const {setUser, setIsAuth} = useAuthStore();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = "Вхід | РФК НУБіП";
    }, []);

    const onFinish = async (values: Login) => {
        setLoading(true);
        if (!executeRecaptcha) {
            return;
        }
        const reCaptchaToken = await executeRecaptcha();
        const model: CreateLogin = {
            ...values,
            reCaptchaToken: reCaptchaToken,
        } as CreateLogin;

        axios
            .post(process.env.REACT_APP_SERVER_URL + "api/auth/login", model)
            .then((response) => {
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("refreshToken", response.data.refreshToken);

                const userData = jwtDecode<any>(response.data.token);
                const user: UserType = {
                    id: userData.id,
                    firstName: userData.firstName,
                    middleName: userData.middleName,
                    lastName: userData.lastName,
                    email:
                        userData[
                            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
                            ],
                    userName: userData.userName,
                    avatarImage: userData.avatarImage,
                    roles:
                        userData[
                            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                            ],
                };

                setUser(user);
                setIsAuth(true);
                message.success(response?.data.message);

                setTimeout(async () => {
                    navigate("/home");
                }, 1000);
            }).catch((error) => {
            message.error(error.response?.data.message);
        });
        setLoading(false);
    }

    return (
        <>
            <h1 className="auth-header">Увійти до репозитарію</h1>
            <h2 className="college-header">Рівненський фаховий коледж НУБіП України</h2>
            <p className="no-profile-text">Не маєте закладеного профілю? <Link
                className="auth-link"
                to="/register">Реєстрація</Link></p>
            <Form
                initialValues={{remember: true}}
                autoComplete="on"
                disabled={loading}
                onFinish={onFinish}
            >
                <Form.Item
                    name="email"
                    rules={[
                        {
                            type: "email",
                            message: "Хибний формат поштової скриньки!",
                        },
                        {
                            required: true,
                            message: "Введіть вашу поштову скриньку!",
                        },
                    ]}
                    className="form-item-login"
                    hasFeedback
                >
                    <Input
                        prefix={<UserOutlined className="site-form-item-icon"/>}
                        className="form-input-login"
                        placeholder="Поштова скринька"
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        { required: true, message: "Введіть ваш пароль!" },
                        {
                            pattern: /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/,
                            message: "Пароль має складатися з 8-32 символів, великої літери, цифри та\nспеціального символу (@$!%*?&)!",
                        },
                        {
                            pattern: /^(?=.*[^A-Za-z\d\s])/, // Ensures at least one non-alphanumeric character
                            message: "Пароль має містити принаймні один неалфавітний символ!",
                        },
                    ]}
                    className="form-item-login"
                    hasFeedback
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon"/>}
                        type="password"
                        placeholder="Пароль"
                        className="form-input-login"
                        iconRender={(visible: any) =>
                            visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>
                        }
                    />
                </Form.Item>
                <Form.Item>
                    <div className="form-wrapper-remember-me">
                        <div>
                            <Form.Item name="isRemember" valuePropName="checked" noStyle>
                                <Checkbox><span
                                    className="remember-me-text">Запам'ятати мене</span></Checkbox>
                            </Form.Item>
                        </div>
                        <div>
                            <Link to="/password-recovery"><span className="forget-password-text">Відновити пароль</span></Link>
                        </div>
                    </div>
                </Form.Item>
                <Form.Item>
                    <Button loading={loading} disabled={loading} type="primary" className="form-btn-submit-login"
                            htmlType="submit">
                        Увійти
                    </Button>
                    <div className="form-separator-login">
                        <span>або</span>
                    </div>
                    <GoogleAuth
                        className="form-btn-google-login"
                        loading={loading}
                        handleGoogleSuccess={(response: any) => {
                            axios
                                .post(
                                    process.env.REACT_APP_SERVER_URL + "api/auth/google-one-tap-login",
                                    {
                                        provider: "Google",
                                        token: response.access_token,
                                    }
                                )
                                .then((response) => {
                                    localStorage.setItem("token", response.data.token);
                                    localStorage.setItem("refreshToken", response.data.refreshToken);

                                    const userData = jwtDecode<any>(response.data.token);
                                    const user: UserType = {
                                        id: userData.id,
                                        firstName: userData.firstName,
                                        middleName: userData.middleName,
                                        lastName: userData.lastName,
                                        email:
                                            userData[
                                                "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
                                                ],
                                        userName: userData.userName,
                                        avatarImage: userData.avatarImage,
                                        roles:
                                            userData[
                                                "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                                                ],
                                    };

                                    setUser(user);
                                    message.success(response.data.message);
                                })
                                .catch((error) => {
                                    message.error(error.response?.data.message);
                                });
                        }}
                        text="Увійти за допомогою Google"
                    />
                </Form.Item>
            </Form>
        </>
    );
};

export default Login;
