import '../../../css/components/auth/passwordRecovery.css';

import {UserOutlined} from "@ant-design/icons";
import {Button, Form, Input, message} from "antd";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {Link} from "react-router-dom";
import {useEffect} from 'react';
import axios from "axios";

export interface RecoveryPassword {
    email: string;
    reCaptchaToken: string;
}

const PasswordRecovery = () => {
    const {executeRecaptcha} = useGoogleReCaptcha();

    useEffect(() => {
        document.title = "Відновлення паролю | РФК НУБіП";
    }, []);

    const onFinish = async (values: any) => {
        if (!executeRecaptcha) {
            return;
        }

        const reCaptchaToken = await executeRecaptcha();
        const model: RecoveryPassword = {
            email: values.email,
            reCaptchaToken: reCaptchaToken,
        };

        const response = await axios.post(
            process.env.REACT_APP_SERVER_URL + "api/auth/recover-password",
            model
        );

        message.success(response.data.message);
    };

    return (
        <>
            <h1 className="auth-header">Відновити пароль</h1>
            <h2 className="college-header">Рівненський фаховий коледж НУБіП України</h2>
            <p className="no-profile-text">Повернутися назад? <Link
                className="auth-link"
                to="/login">Увійти</Link></p>
            <Form
                initialValues={{remember: true}}
                autoComplete="on"
                onFinish={onFinish}
            >
                <Form.Item
                    name="email"
                    rules={[
                        {
                            type: "email",
                            message: "Хибний формат поштової скриньки!",
                        },
                        {
                            required: true,
                            message: "Будь ласка введіть вашу поштову скриньку!",
                        },
                    ]}
                    hasFeedback
                >
                    <Input
                        prefix={<UserOutlined className="site-form-item-icon"/>}
                        className="form-input-recover-password"
                        placeholder="Поштова скринька"
                    />
                </Form.Item>
                <Button type="primary" className="form-btn-submit-recover-password" htmlType="submit">
                    Надіслати лист
                </Button>
            </Form>
        </>
    );
};

export default PasswordRecovery;
