import '../../../css/components/auth/register.css';
import {Button, Form, Input, message} from "antd";
import {Link, useNavigate} from "react-router-dom";
import GoogleAuth from "../../common/google-auth/google-auth";
import {EyeInvisibleOutlined, EyeTwoTone, LockOutlined, UserOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import axios from "axios";

export interface Register {
    email: string,
    password: string,
    firstName: string,
    lastName: string,
}

export interface CreateRegister {
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    reCaptchaToken: string,
}

const Register = () => {
    const {executeRecaptcha} = useGoogleReCaptcha();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = "Реєстрація | РФК НУБіП";
    }, []);

    const onFinish = async (values: Register) => {
        setLoading(true);
        if (!executeRecaptcha) {
            return;
        }

        const reCaptchaToken = await executeRecaptcha();
        const model: CreateRegister = {
            ...values,
            reCaptchaToken: reCaptchaToken,
        };

        axios.post(process.env.REACT_APP_SERVER_URL + "api/auth/register", model).then((response) => {
            message.success(response?.data.message);
            setTimeout(() => {
                navigate("/send-email");
            }, 2000);
        }).catch((error) => {
            message.error(error.response?.data.message);
        });
        setLoading(false);
    };

    return (
        <>
            <h1 className="auth-header">Зареєструватися до репозитарію</h1>
            <h2 className="college-header">Рівненський фаховий коледж НУБіП України</h2>
            <p className="no-profile-text">Вже маєте закладений профіль? <Link
                className="auth-link"
                to="/login">Увійти</Link></p>
            <Form
                initialValues={{remember: true}}
                autoComplete="on"
                disabled={loading}
                onFinish={onFinish}
            >
                <div className="form-initials-wrapper">
                    <Form.Item
                        name="firstName"
                        rules={[
                            {
                                required: true,
                                message: "Будь ласка введіть ім'я!",
                            },
                        ]}
                        className="form-item-register"
                        hasFeedback
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon"/>}
                               className="form-input-register" placeholder="Ім'я"/>
                    </Form.Item>
                    <Form.Item
                        name="lastName"
                        rules={[
                            {
                                required: true,
                                message: "Будь ласка введіть прізвище!",
                            },
                        ]}
                        className="form-item-register"
                        hasFeedback
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon"/>}
                               className="form-input-register" placeholder="Прізвище"/>
                    </Form.Item>
                    <Form.Item
                        name="middleName"
                        rules={[
                            {
                                required: true,
                                message: "Будь ласка введіть по батькові!",
                            },
                        ]}
                        className="form-item-register"
                        hasFeedback
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon"/>}
                               className="form-input-register" placeholder="По батькові"/>
                    </Form.Item>
                </div>
                <Form.Item
                    name="email"
                    rules={[
                        {
                            type: "email",
                            message: "Хибний формат поштової скриньки!",
                        },
                        {
                            required: true,
                            message: "Будь ласка введіть поштову скриньку!",
                        },
                    ]}
                    className="form-item-register"
                    hasFeedback
                >
                    <Input
                        prefix={<UserOutlined className="site-form-item-icon"/>}
                        className="form-input-register"
                        placeholder="Поштова скринька"
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        { required: true, message: "Введіть ваш пароль!" },
                        {
                            pattern: /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/,
                            message: "Пароль має складатися з 8-32 символів, великої літери, цифри та\nспеціального символу (@$!%*?&)!",
                        },
                        {
                            pattern: /^(?=.*[^A-Za-z\d\s])/, // Ensures at least one non-alphanumeric character
                            message: "Пароль має містити принаймні один неалфавітний символ!",
                        },
                    ]}
                    className="form-item-register"
                    hasFeedback
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon"/>}
                        type="password"
                        className="form-input-register"
                        placeholder="Пароль"
                        iconRender={(visible) =>
                            visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>
                        }
                    />
                </Form.Item>
                <Form.Item
                    name="confirmPassword"
                    dependencies={["password"]}
                    className="form-item-register"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: "Будь ласка введіть пароль!",
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue("password") === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error("Ваші паролі не співпадають!")
                                );
                            },
                        }),
                    ]}
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon"/>}
                        type="password"
                        className="form-input-register"
                        placeholder="Пароль повторно"
                        iconRender={(visible: any) =>
                            visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>
                        }
                    />
                </Form.Item>
                <Form.Item className="form-item-register">
                    <Button loading={loading} type="primary" className="form-btn-submit-register" htmlType="submit">
                        Зареєструватись
                    </Button>
                    <div className="form-separator-register">
                        <span>або</span>
                    </div>
                    <GoogleAuth
                        loading={loading}
                        handleGoogleSuccess={(response: any) => {
                            axios
                                .post(process.env.REACT_APP_SERVER_URL + 'api/auth/google-one-tap-register', {
                                    provider: "Google",
                                    token: response.access_token,
                                })
                                .then((response) => {
                                    message.success(response?.data.message);
                                })
                                .catch((err) => {
                                    message.error(err.response?.data.message);
                                });

                            setTimeout(() => {
                                navigate("/login");
                            }, 1000);
                        }}
                        text="Зареєструватись за допомогою Google"
                    />
                </Form.Item>
            </Form>
        </>
    );
};

export default Register;
