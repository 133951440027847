import '../../css/components/containers/layout.css';

import React, {lazy, Suspense, useEffect} from "react";

import Header from "../home/header";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

interface StandardLayoutProps {
    children1: React.ReactNode;
    children2: React.ReactNode;
}

const UpdateProject = lazy(() => import("../home/modal/update-project"));

const StandardLayout = ({children1, children2}: StandardLayoutProps) => {
    // const desktopXtablet = useMediaQuery({minWidth: 768});
    // const smartphone = useMediaQuery({maxWidth: 767});

    useEffect(() => {
        document.title = "Головна | РФК НУБіП";
    }, []);

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY as string}
        >
            <div className="layout-wrapper">
                <header className="layout-header">
                    <div>
                        <Header/>
                    </div>
                </header>
                <div className="layout-content-wrapper">
                    <section className="left-content-wrapper">
                        {children1}
                    </section>
                    <section className="right-content-wrapper">
                        {children2}
                    </section>
                </div>
            </div>

            <Suspense fallback={<></>}>
                <UpdateProject/>
            </Suspense>
        </GoogleReCaptchaProvider>
    );
};

export default StandardLayout;
