import {create} from "zustand/react";
import {persist} from 'zustand/middleware'

export type UserType = {
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    userName: string;
    avatarImage: string;
    roles: Array<string>;
}

type AuthStore = {
    isAuth: boolean;
    setIsAuth: (isAuth: boolean) => void;
    user: UserType;
    setUser: (user: UserType) => void;
    logout: () => void;
}

export const useAuthStore = create<AuthStore>()(
    persist(
        (set) => ({
            isAuth: false,
            user: {
                id: "",
                firstName: "",
                middleName: "",
                lastName: "",
                email: "",
                userName: "",
                avatarImage: "",
                roles: new Array<string>(),
            },
            setUser: (user: UserType) => set((state) => ({user: user})),
            setIsAuth: (isAuth: boolean) => set((state) => ({isAuth: isAuth})),
            logout: () => set({
                user: {
                    id: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                    email: "",
                    userName: "",
                    avatarImage: "",
                    roles: new Array<string>(),
                },
                isAuth: false
            }),
        }),
        {
            name: "auth-storage", // unique name for storage
            partialize: (state) => ({
                isAuth: state.isAuth,
                user: state.user,
            }),
        }
    )
);