import instance from "../api/configuration/configure";
import {message} from "antd";

export const refreshToken = async (refreshToken: string) => {
    try {
        const response = await instance.post(`${process.env.REACT_APP_SERVER_URL}api/auth/refresh-token`, refreshToken);
        return response.data;
    } catch (error) {
        message?.error("Помилка заміни токену");
    }
};
