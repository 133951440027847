import {create} from "zustand/react";

export interface SearchParams {
    query: string | null;
    year: string | null;
    categoryId: string | null;
    fullName: string | null;
    type: number | null;
    tags: Array<string>;
    rejectedProjects: boolean | string;
    ownProjects: boolean;
}

export interface Category {
    id: string;
    name: string;
}

type FiltersStore = {
    searchParams: SearchParams;
    categories: Array<Category>;
    yearsList: Array<string>;
    authorsList: Array<string>;

    setCategories: (categories: Array<Category>) => void;
    setYearsList: (yearsList: Array<string>) => void;
    setAuthorsList: (authorsList: Array<string>) => void;
    setSearchParams: (params: SearchParams) => void;
}

export const useFiltersStore = create<FiltersStore>()(
    (set) => ({
        searchParams: {
            query: "",
            pageIndex: 1,
            year: "",
            categoryId: "",
            fullName: "",
            type: null,
            tags: [] as Array<string>,
            rejectedProjects: "",
            ownProjects: false,
        } as SearchParams,
        categories: [] as Array<Category>,
        yearsList: [] as Array<string>,
        authorsList: [] as Array<string>,

        setCategories: (categories: Array<Category>) => set((state) => ({categories: categories})),
        setYearsList: (yearsList: Array<string>) => set((state) => ({yearsList: yearsList})),
        setAuthorsList: (authorsList: Array<string>) => set((state) => ({authorsList: authorsList})),
        setSearchParams: (params: SearchParams) => set((state) => ({searchParams: params})),
    })
);
