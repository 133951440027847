import "../../css/components/home/content-card.css";

import {Card, Image} from "antd";
import Meta from "antd/es/card/Meta";
import {Link} from "react-router-dom";
import Paragraph from "antd/es/typography/Paragraph";

export interface ContentCardProps {
    id: string;
    title: string;
    description: string;
    image: string;
}

const ContentCard = ({id, title, description, image}: ContentCardProps) => {
    return (
        <div className="content-card-wrapper">
            <Card
                hoverable={true}
                bordered={true}
                className="content-card"
                cover={<Image alt="CardImage" src={image} className="content-card-image"/>}
            >
                <Link to={"/home/" + id}>
                    <Paragraph className="content-card-title">{title}</Paragraph>
                    <Meta description={description} className="content-card-meta"/>
                </Link>
            </Card>
        </div>
    );
}

export default ContentCard;
