import '../../css/components/landing/landing.css';

import rcNubipLogo from "../../assets/logos/rcnubip-logo.png";

import leftLandingSection from "../../assets/backgrounds/landing-1.jpg";
import topLandingSection from "../../assets/backgrounds/landing-2.jpg";

import React, {useEffect, useState} from "react";
import {Button, Statistic, Tooltip} from "antd";
import {BarsOutlined, FileProtectOutlined, UserOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import axios from "axios";
import {useLandingStore} from "../../stores/landingStore";

const LandingPage = () => {
    const {statistics, setStatistics} = useLandingStore();

    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER_URL + "api/statistics/total")
            .then((response) => {
                setStatistics(response.data);
            });
    }, []);

    return (
        <div className="landing-wrapper">
            <div className="left-landing-section">
                <img src={leftLandingSection} alt=""/>
                <div className="landing-header-text-wrapper">
                    <h1>Репозитарій наукових робіт<br/><br/>ВСП «Рівненський фаховий коледж НУБіП України»</h1>
                </div>
            </div>
            <div className="right-landing-section">
                <div className="inner-top-landing-section">
                    <div className="inner-top-left-landing-section">
                        <div>
                            <img src={rcNubipLogo} alt=""/>
                            <div>
                                <Link to="/login"><Button className="landing-auth-button" type="dashed">Увійти</Button></Link>
                                <Link to="/register"><Button className="landing-auth-button"
                                                             type="primary">Зареєструватись</Button></Link>
                            </div>
                        </div>
                    </div>
                    <div className="inner-top-right-landing-section">
                        <img src={topLandingSection} alt=""/>
                    </div>
                </div>
                <div className="inner-bottom-landing-section">
                    <div className="statistics-wrapper">
                        <Tooltip
                            placement="top"
                            title="Кількість зареєстрованих користувачів"
                        >
                            <Statistic
                                className="header-tooltip-statistic"
                                value={statistics?.totalUsersCount}
                                valueStyle={{color: "#F9F7F7", fontSize: "4em"}}
                                prefix={<UserOutlined/>}
                            />
                        </Tooltip>
                        <Tooltip
                            placement="top"
                            title="Кількість затверджених наукових робіт"
                        >
                            <Statistic
                                className="header-tooltip-statistic"
                                value={statistics?.totalVerifiedResearchProjectsCount}
                                valueStyle={{color: "#F9F7F7", fontSize: "4em"}}
                                prefix={<FileProtectOutlined/>}
                            />
                        </Tooltip>
                        <Tooltip
                            placement="top"
                            title="Кількість наукових робіт на затвердженні"
                        >
                            <Statistic
                                className="header-tooltip-statistic"
                                value={statistics?.totalInApprovalResearchProjectsCount}
                                valueStyle={{color: "#F9F7F7", fontSize: "4em"}}
                                prefix={<FileProtectOutlined/>}
                            />
                        </Tooltip>
                        <Tooltip placement="top" title="Кількість спеціальностей">
                            <Statistic
                                className="header-tooltip-statistic"
                                value={statistics?.totalCategoriesCount}
                                valueStyle={{color: "#F9F7F7", fontSize: "4em"}}
                                prefix={<BarsOutlined/>}
                            />
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandingPage;
