import {message, Result} from "antd";
import {useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import axios from "axios";

export interface ConfirmEmail {
    email: string;
    token: string;
}

const EmailConfirmation = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        document.title = "Підтвердження реєстрації | РФК НУБіП";

        axios.post(
            process.env.REACT_APP_SERVER_URL + "api/auth/confirm-email",
            {
                email: searchParams.get("email"),
                token: searchParams.get("token"),
            } as ConfirmEmail
        ).then((response: any) => {
            message.success(response?.data.message);
        });

        setTimeout(() => {
            navigate("/login");
        }, 4500);
    }, []);

    return (
        <Result
            status="success"
            title="Підтвердження реєстрації"
            subTitle="Дякуємо, ваша почтова скринька підтверджена!"
        />
    );
};

export default EmailConfirmation;
