import style from "../../css/components/home/details.module.css";

import ReactQuill from "react-quill";
import React, {useEffect, useState} from "react";
import {Button, Image, message, Popconfirm, Tooltip, Typography} from "antd";
import {CopyOutlined, DownloadOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {Roles} from "../../constants/auth/userRoles";
import ShowAvatar from "../common/avatar-image/avatar";
import {useAuthStore} from "../../stores/authStore";
import instance from "../../api/configuration/configure";
import {useNavigate, useParams} from "react-router-dom";
import {ProjectDetails, useContentStore} from "../../stores/contentStore";
import {ResearchProjectTypes} from "../../constants/home/researchProjectTypes";
import {classnames} from "../common/classnames";

const Details = () => {
    const {user} = useAuthStore();
    const {id} = useParams();
    const navigate = useNavigate();
    const {details, setDetails, data, setData, setUpdateOpen} = useContentStore();

    const [file, SetFile] = useState<string>("");

    useEffect(() => {
        if (id !== undefined) {
            instance.get(`api/research-project/${id}`).then((response) => {
                setDetails(response.data);
            }).catch((err) => {
                message.error(err.message);
            });
        }
    }, [id]);

    useEffect(() => {
        if (id !== undefined) {
            let fileUrl = "";
            if (details.fileName !== "") {
                instance
                    .get(
                        `${process.env.REACT_APP_SERVER_URL}storage/file?filename=${details.fileName}`,
                        {
                            responseType: "blob",
                        }
                    ).then((fileResponse) => {
                    fileUrl = URL.createObjectURL(fileResponse.data);
                    SetFile(fileUrl);
                });
            }

            return (() => {
                if (fileUrl !== "") {
                    URL.revokeObjectURL(fileUrl);
                }
            });
        }
    }, [details]);

    return (
        <section className={classnames(style.sectionContent, "hide-scrollbar")}>
            <div className={style.contentWrapper}>
                <div>
                    <div className={style.contentWrapperProjectName}>
                        <Typography className={style.contentWrapperProjectNameText}>
                            {details.name}
                        </Typography>
                    </div>
                    <div>
                        <Typography className={style.contentWrapperProjectKeywordsText}>
                            Ключові слова: {details.tags.map((x) => x + ", ")}...
                        </Typography>
                    </div>
                    <div className={style.contentWrapperAuthorLinksBlock}>
                        <div className={style.contentWrapperAuthorBlock}>
                            <ShowAvatar value={details.authorAvatar}/>
                            <Typography className={style.contentWrapperAuthorText}>
                                {details.author}
                            </Typography>
                        </div>
                        <div className={style.contentWrapperLinksBlock}>
                            {/*<Tooltip title="Посилання до наукової роботи для не зареєстрованих користувачів"
                                     placement="topRight" className={style.contentCopyLinkWrapper}>
                                <Typography>Публічне посилання</Typography>
                                <CopyOutlined className={style.contentCopyLink} onClick={async () => {
                                    await navigator.clipboard.writeText(window.location.href.replace("/home", ''))
                                }}/>
                            </Tooltip>*/}
                            <Tooltip title="Посилання до наукової роботи для зареєстрованих користувачів"
                                     placement="topRight" className={style.contentCopyLinkWrapper}>
                                <Typography>Приватне посилання</Typography>
                                <CopyOutlined className={style.contentCopyLink} onClick={async () => {
                                    await navigator.clipboard.writeText(window.location.href)
                                }}/>
                            </Tooltip>
                        </div>
                    </div>
                    <div className={style.contentWrapperDownBlock}>
                        <div className={style.contentWrapperImageAdminButtons}>
                            <Image
                                src={`${process.env.REACT_APP_SERVER_URL}storage/image?filename=${details.coverImageName}`}
                                className={style.contentWrapperCoverImage}
                                alt="Cover Image"
                            />
                            {(details.fileName !== "" ||
                                user?.roles.includes(Roles.ADMINISTRATOR)) && (
                                <div className={style.contentWrapperAdminButtonsBlock}>
                                    {(details.isVerified !== true || user?.roles.includes(Roles.ADMINISTRATOR)) && (
                                        <Button
                                            className={style.contentWrapperFullSpace}
                                            onClick={() => setUpdateOpen(true)}
                                        >
                                            Редагувати
                                        </Button>
                                    )}
                                    {(details.isVerified === false || user?.roles.includes(Roles.ADMINISTRATOR)) && (
                                        <Popconfirm
                                            title="Видалення проєкту"
                                            description="Чи ви впевнені щодо видалення цього проєкту?"
                                            onConfirm={() => {
                                                instance
                                                    .delete("api/research-project?id=" + details.id)
                                                    .then((response) => {
                                                        setDetails({
                                                            id: "",
                                                            name: "",
                                                            fileName: "",
                                                            coverImageName: "",
                                                            description: "",
                                                            categoryName: "",
                                                            author: "",
                                                            authorAvatar: "",
                                                            dateCreated: "",
                                                            dateEdited: "",
                                                            type: 0,
                                                            tags: [],
                                                            isVerified: null,
                                                        } as ProjectDetails);
                                                        setData({
                                                            ...data,
                                                            items: data.items.filter(item => item.id !== id),
                                                            totalCount: data.totalCount - 1
                                                        });
                                                        navigate("/home");

                                                        message.success(response.data.message);
                                                    })
                                                    .catch((error) => {
                                                        message.error(error?.response.data.message);
                                                    });
                                            }}
                                            icon={
                                                <QuestionCircleOutlined className={style.contentWrapperRedIcon}/>
                                            }
                                        >
                                            <Button className={style.contentWrapperFullSpace}>Видалити</Button>
                                        </Popconfirm>
                                    )}
                                </div>
                            )}
                            {(details.fileName !== "" ||
                                user?.roles.includes(Roles.ADMINISTRATOR)) && (
                                <a href={file} className={style.contentWrapperFullWidth}
                                   download={details.name.replace(/ /g, '_') + ".pdf"}>
                                    <Button className={style.contentWrapperFullWidth}>
                                        <DownloadOutlined/>Завантажити проєкт
                                    </Button>
                                </a>
                            )}
                        </div>
                        <div className={style.contentDetailsDescription}>
                            <ReactQuill
                                value={details.description}
                                readOnly={true}
                                theme="bubble"
                            />
                        </div>
                    </div>
                </div>
                <div className={style.contentWrapperDatesBlock}>
                    <Typography>
                        <b>Дата створення: </b>
                        {details.dateCreated}
                    </Typography>
                    <Typography className={style.textSpecialityContent}>
                        {details.categoryName} (
                        {details.type === 0
                            ? ResearchProjectTypes.GRADUATE_WORK
                            : details.type === 1
                                ? ResearchProjectTypes.COURSEWORK
                                : details.type === 2
                                    ? ResearchProjectTypes.EDUCATIONAL_MATERIALS
                                    : ResearchProjectTypes.UNKNOWN_WORK_TYPE}
                        )
                    </Typography>
                    <Typography>
                        <b>Дата редагування: </b>
                        {details.dateEdited}
                    </Typography>
                </div>
            </div>
        </section>
    );
}

export default Details;
