import "../../css/components/containers/authLayout.css";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {Outlet} from "react-router-dom";
import rcNubip from "../../assets/logos/rcnubip-logo.png";
import {Carousel} from "antd";
import carouselOne from "../../assets/backgrounds/carousel-1.jpg";
import carouselTwo from "../../assets/backgrounds/carousel-2.jpg";
import carouselThree from "../../assets/backgrounds/carousel-3.jpg";

const AuthLayout = () => {
    return (
        <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID as string}
        >
            <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY as string}
            >
                <div className="sections-wrapper">
                    <div className="carousel-wrapper">
                        <div className="carousel-logo-overlap">
                            <img src={rcNubip} alt="SSS RVC NUBNM of Ukraine logo"/>
                        </div>
                        <Carousel className="carousel" adaptiveHeight={true} autoplay autoplaySpeed={7500}>
                            <div>
                                <img src={carouselOne} alt="Carousel img nr. 1"/>
                            </div>
                            <div>
                                <img src={carouselTwo} alt="Carousel img nr. 2"/>
                            </div>
                            <div>
                                <img src={carouselThree} alt="Carousel img nr. 3"/>
                            </div>
                        </Carousel>
                    </div>
                    <div className="auth-wrapper">
                        <div>
                            <Outlet/>
                        </div>
                        <div className="auth-copyright">
                            <small>© 2022-{new Date().getFullYear()} SSS "RVC NUBNM of Ukraine", All rights reserved</small>
                        </div>
                    </div>
                </div>
            </GoogleReCaptchaProvider>
        </GoogleOAuthProvider>
    );
};

export default AuthLayout;
