import style from "../../css/components/home/content.module.css";

import {AutoComplete, Empty, Input, message} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {useFiltersStore} from "../../stores/filtersStore";
import ContentCard from "./contentCard";
import {useEffect, useRef, useState} from "react";
import instance from "../../api/configuration/configure";
import {useContentStore} from "../../stores/contentStore";
import InfiniteScroll from "react-infinite-scroll-component";

const Content = () => {
    const {searchParams, setSearchParams} = useFiltersStore();
    const {data, setData, page, setPage} = useContentStore();
    const [loading, setLoading] = useState<boolean>(false);
    const filtersDivRef = useRef<HTMLDivElement | null>(null);

    // Function to fetch the next page of data
    const fetchNextPage = async () => {
        if (loading || !data.hasNextPage) return;
        setLoading(true);
        setPage(page + 1);
    };

    // Effect to fetch data on page change
    useEffect(() => {
        if (!data.hasNextPage) return; // Do not fetch if no more pages

        const fetchData = async () => {
            try {
                let tags = null;
                if (searchParams.tags != null) {
                    tags = JSON.stringify(searchParams.tags).replace("%22", '"');
                }
                const response = await instance.get("api/research-project", {
                    params: {
                        categoryId: searchParams.categoryId,
                        pageIndex: page,
                        projectName: searchParams.query,
                        year: searchParams.year,
                        fullName: searchParams.fullName,
                        type: searchParams.type,
                        tags: tags,
                        rejectedProjects: searchParams.rejectedProjects,
                        ownProjects: searchParams.ownProjects,
                    },
                });
                setData({
                    ...data,
                    pageIndex: response.data.pageIndex,
                    hasNextPage: response.data.hasNextPage,
                    hasPreviousPage: response.data.hasPreviousPage,
                    pageSize: response.data.pageSize,
                    items: [
                        ...data.items,
                        ...response?.data.items
                    ]
                });
            } catch (error) {
                message?.error("Помилка завантаження робіт");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [page]);

    useEffect(() => {
        if (filtersDivRef.current) {
            filtersDivRef.current.scrollTop = 0; // Scroll the div to the top
        }
    }, [searchParams]);

    // Search input handler
    const onSearch = (value: string) => {
        setSearchParams({
            ...searchParams,
            query: value,
        });
    };

    // Options for AutoComplete
    const optionsProject = data.items.map((result: any) => ({
        value: result.name,
        label: result.name,
    }));

    return (
        <section className={style.contentWrapper}>
            <AutoComplete
                options={optionsProject}
                onChange={onSearch}
                className={style.searchInput}
                value={searchParams.query}
                backfill
                allowClear
            >
                <Input prefix={<SearchOutlined/>} placeholder="Введіть назву наукової роботи"/>
            </AutoComplete>
            <div className={style.listWrapper} id="scrollableDiv" ref={filtersDivRef}>
                {data?.items?.length > 0 && (
                    <InfiniteScroll
                        dataLength={data.items.length}
                        next={fetchNextPage}
                        hasMore={data.hasNextPage}
                        loader={<></>}
                        scrollableTarget="scrollableDiv"
                        endMessage={<p className={style.textScroll}>Вітаю, ви догортали до кінця!</p>}
                        scrollThreshold={1}
                    >
                        <div className={style.scrollListWrapper}>
                            {data?.items?.map((value, key) => (
                                <ContentCard
                                    id={value.id}
                                    key={key}
                                    title={value.name}
                                    description={value.author}
                                    image={process.env.REACT_APP_SERVER_URL + "storage/image?filename=" + value.coverImage}
                                />
                            ))}
                        </div>
                    </InfiniteScroll>
                )}
            </div>
            {data?.items?.length === 0 && (
                <div className={style.noProjectsFound}>
                    <Empty
                        className="noselect"
                        description="Наукові роботи відсутні"
                    />
                </div>
            )}
        </section>
    );
};

export default Content;
