import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {Result} from "antd";

const PasswordRecoveryConfirmationResult = () => {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Підтвердження відновлення паролю | РФК НУБіП";

        setTimeout(() => {
            navigate("/login");
        }, 7500);
    }, []);

    return (
        <Result
            status="success"
            title="Підтвердження відновлення паролю"
            subTitle="Дякуємо, ваш пароль успішно змінено!"
        />
    );
};

export default PasswordRecoveryConfirmationResult;
