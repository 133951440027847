import "../../css/components/admin-panel/admin-list.css";

import React from "react";
import {useAuthStore} from "../../stores/authStore";
import {Button, message} from "antd";
import {useNavigate} from "react-router-dom";
import instance from "../../api/configuration/configure";

const AdminList = () => {
    const navigate = useNavigate();
    const {user} = useAuthStore();

    const onImagesFetch = () => {
        instance.get("backup/images", {
            responseType: "blob"
        }).then((response) => {
            let filename = 'downloaded-file.zip'; // Fallback filename

            // Extract Content-Disposition header
            const contentDisposition = response.headers['content-disposition'];
            if (contentDisposition) {
                try {
                    // Check for UTF-8 encoded filename* first
                    const utf8FilenameMatch = contentDisposition.match(/filename\*=UTF-8''([^;]+)/);
                    if (utf8FilenameMatch) {
                        filename = decodeURIComponent(utf8FilenameMatch[1]); // Decode URI-encoded UTF-8 string
                    } else {
                        // Fallback to quoted filename
                        const simpleFilenameMatch = contentDisposition.match(/filename="([^"]+)"/);
                        if (simpleFilenameMatch) {
                            filename = simpleFilenameMatch[1];
                        }
                    }
                } catch (error) {
                    console.error('Error extracting filename:', error);
                }
            } else {
                console.warn('No Content-Disposition header found.');
            }

            // Create a Blob and download the file
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = filename; // Use the extracted filename
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Remove link element
            window.URL.revokeObjectURL(url); // Free memory

            // Success messages
            message?.success("Витяг даних проведено успішно!");
            message?.success("Код доступу відправлено на поштову скриньку");
        }).catch(() => {
            message?.error("Щось пішло не так");
        });
    }

    const onFilesFetch = () => {
        instance.get("backup/files", {
            responseType: "blob",
            headers: {'Accept': 'application/octet-stream'},
            withCredentials: true
        }).then((response) => {
            let filename = 'downloaded-file.zip'; // Fallback filename

            // Extract Content-Disposition header
            const contentDisposition = response.headers['content-disposition'];
            console.log(response.headers)
            if (contentDisposition) {
                try {
                    // Check for UTF-8 encoded filename* first
                    const utf8FilenameMatch = contentDisposition.match(/filename\*=UTF-8''([^;]+)/);
                    if (utf8FilenameMatch) {
                        filename = decodeURIComponent(utf8FilenameMatch[1]); // Decode URI-encoded UTF-8 string
                    } else {
                        // Fallback to quoted filename
                        const simpleFilenameMatch = contentDisposition.match(/filename="([^"]+)"/);
                        if (simpleFilenameMatch) {
                            filename = simpleFilenameMatch[1];
                        }
                    }
                } catch (error) {
                    console.error('Error extracting filename:', error);
                }
            } else {
                console.warn('No Content-Disposition header found.');
            }

            // Create a Blob and download the file
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = filename; // Use the extracted filename
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Remove link element
            window.URL.revokeObjectURL(url); // Free memory

            message?.success("Витяг даних проведено успішно!");
            message?.success("Код доступу відправлено на поштову скриньку");
        }).catch(() => {
            message?.error("Щось пішло не так");
        });
    }

    return (
        <section className="admin-list-wrapper">
            <h2 className="noselect admin-list-title-func">Функції адміністрування</h2>
            <div>
                {(
                    user?.roles.includes("Викладач") ||
                    user?.roles.includes("Адміністратор")) && (
                    <>
                        <Button
                            type="primary"
                            className="admin-list-btn"
                            onClick={() => navigate("/admin-panel/add-research-project")}
                            ghost
                        >
                            Додати науковий проєкт
                        </Button>
                        <Button
                            type="primary"
                            className="admin-list-btn"
                            onClick={() => navigate("/admin-panel/projects-approval")}
                            ghost
                        >
                            Затвердження проєктів
                        </Button>
                    </>
                )}
                {user?.roles.includes("Адміністратор") && (
                    <>
                        <Button
                            type="primary"
                            className="admin-list-btn"
                            onClick={() => navigate("/admin-panel/users-managing")}
                            ghost
                        >
                            Управління користувачами
                        </Button>
                        <Button
                            type="primary"
                            className="admin-list-btn"
                            onClick={() => navigate("/admin-panel/project-types-managing")}
                            ghost
                            disabled={true}
                        >
                            Управління типами наукових проєктів
                        </Button>
                        <Button
                            type="primary"
                            className="admin-list-btn"
                            onClick={() => navigate("/admin-panel/tags-managing")}
                            ghost
                            disabled={true}
                        >
                            Управління ключовими словами
                        </Button>
                        <Button
                            type="primary"
                            className="admin-list-btn"
                            onClick={() => navigate("/admin-panel/categories-managing")}
                            ghost
                        >
                            Управління спеціальностями
                        </Button>
                        <div className="admin-list-security-wrapper">
                            <h2 className="noselect admin-list-title-security">Функції безпеки даних</h2>
                            <div>
                                <Button
                                    type="primary"
                                    className="admin-list-btn"
                                    onClick={onImagesFetch}
                                    ghost
                                >
                                    Витяг картинок даних
                                </Button>
                                <Button
                                    type="primary"
                                    className="admin-list-btn"
                                    onClick={onFilesFetch}
                                    ghost
                                >
                                    Витяг файлів даних
                                </Button>
                                <Button
                                    type="primary"
                                    className="admin-list-btn"
                                    onClick={() => {
                                    }}
                                    ghost
                                    disabled={true}
                                >
                                    Витяг бази даних
                                </Button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </section>
    );
};

export default AdminList;
