import {create} from "zustand/react";
import {Category} from "./filtersStore";

export interface ApprovalProject {
    id: string;
    name: string;
    description: string;
    fileName: string;
    coverImageName: string;
    dateCreated: string;
    avatarImage: string;
    fullName: string;
    categoryName: string;
    tags: Array<string>;
}

export interface ApprovalProjectsList {
    items: Array<ApprovalProject>;
    totalCount: number;
    pageIndex: number;
    pageSize: number;
    totalPages: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
}

export interface UsersManaging {
    id: string;
    avatar: string;
    email: string;
    firstName: string;
    middleName: string;
    lastName: string;
    currentRole: string;
}

export interface UsersManagingList {
    items: Array<UsersManaging>;
    totalCount: number;
    pageIndex: number;
    pageSize: number;
    totalPages: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
}

type AdminPanelStore = {
    createProjectId: string;
    setCreateProjectId: (id: string) => void;
    approvalProjects: ApprovalProjectsList;
    setApprovalProjects: (project: ApprovalProjectsList) => void;
    usersManaging: UsersManagingList;
    setUsersManaging: (users: UsersManagingList) => void;
    rolesList: Array<string>;
    setRolesList: (roles: Array<string>) => void;
    categories: Array<Category>;
    setCategories: (categories: Array<Category>) => void;
}

export const useAdminPanelStore = create<AdminPanelStore>()(
    (set) => ({
        createProjectId: "",
        setCreateProjectId: (id: string) => set((state) => ({createProjectId: id})),
        approvalProjects: {
            items: [] as Array<ApprovalProject>,
            totalCount: 0,
            pageIndex: 0,
            pageSize: 0,
            totalPages: 0,
            hasPreviousPage: false,
            hasNextPage: false,
        } as ApprovalProjectsList,
        setApprovalProjects: (project: ApprovalProjectsList) => set((state) => ({approvalProjects: project})),
        usersManaging: {
            items: [] as Array<UsersManaging>,
            totalCount: 0,
            pageIndex: 0,
            pageSize: 0,
            totalPages: 0,
            hasPreviousPage: false,
            hasNextPage: false,
        } as UsersManagingList,
        setUsersManaging: (users: UsersManagingList) => set((state) => ({usersManaging: users})),
        rolesList: [] as Array<string>,
        setRolesList: (roles: Array<string>) => set((state) => ({rolesList: roles})),
        categories: [] as Array<Category>,
        setCategories: (categories: Array<Category>) => set((state) => ({categories: categories})),
    })
);
