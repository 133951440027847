import instance from "../../../api/configuration/configure";
import {message} from "antd";
import axios from "axios";

export const SetAvatarImage = (file: any) => {
    return async () => {
        try {
            const formData = new FormData();

            formData.append("image", file);

            const response = await instance.put(
                "api/user/set-avatar-image",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        withCredentials: true,
                    },
                }
            );

            message.success(response?.data.message);
            return response?.data.image;
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return null;
        }
    };
};
