import {Route, Routes, useNavigate} from "react-router-dom";
import PublicRoute from "./components/common/routes/public-route";
import PasswordRecoveryConfirmation from "./components/auth/passwordRecovery/passwordRecoveryConfirmation";
import PasswordRecovery from "./components/auth/passwordRecovery/passwordRecovery";
import EmailInfoSend from "./components/auth/email/emailInfoSend";
import Register from "./components/auth/register/register";
import EmailConfirmation from "./components/auth/email/emailConfirmation";
import Login from "./components/auth/login/login";
import AuthLayout from "./components/containers/authLayout";
import PasswordRecoveryConfirmationResult from "./components/auth/passwordRecovery/passwordRecoveryConfirmationResult";
import LandingPage from "./components/landing/landing";
import {useAuthStore} from "./stores/authStore";
import PrivateRoute from "./components/common/routes/private-route";
import StandardLayout from "./components/containers/standardLayout";
import Content from "./components/home/content";
import Details from "./components/home/details";
import Filters from "./components/home/filters";
import AdminList from "./components/admin-panel/admin-list";
import AddProject from "./components/admin-panel/add-project";
import ProjectsApproval from "./components/admin-panel/projects-approval";
import UsersManaging from "./components/admin-panel/users-managing";
import CategoriesManagingPage from "./components/admin-panel/categories-managing";
import TagsManaging from "./components/admin-panel/tags-managing";
import ProjectsTypesManaging from "./components/admin-panel/projects-types-managing";
import {useEffect} from "react";
import {setNavigateFunction} from "./components/common/navigateHelper";

const App = () => {
    const {isAuth} = useAuthStore();
    const navigate = useNavigate();

    useEffect(() => {
        setNavigateFunction(navigate); // Set the navigate function once
    }, [navigate]);

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <PublicRoute isAuth={isAuth}>
                        <LandingPage/>
                    </PublicRoute>
                }
            />

            <Route path="/" element={<AuthLayout/>}>
                <Route
                    index
                    path="/:id"
                    element={
                        <PublicRoute isAuth={isAuth}>
                            {/*<PrivateDetails/>*/}
                        </PublicRoute>
                    }
                />
                <Route
                    index
                    path="/login"
                    element={
                        <PublicRoute isAuth={isAuth}>
                            <Login/>
                        </PublicRoute>
                    }
                />
                <Route
                    path="/register"
                    element={
                        <PublicRoute isAuth={isAuth}>
                            <Register/>
                        </PublicRoute>
                    }
                />
                <Route
                    path="/confirm-email"
                    element={
                        <PublicRoute isAuth={isAuth}>
                            <EmailConfirmation/>
                        </PublicRoute>
                    }
                />
                <Route
                    path="/send-email"
                    element={
                        <PublicRoute isAuth={isAuth}>
                            <EmailInfoSend/>
                        </PublicRoute>
                    }
                />
                <Route
                    path="/password-recovery"
                    element={
                        <PublicRoute isAuth={isAuth}>
                            <PasswordRecovery/>
                        </PublicRoute>
                    }
                />
                <Route
                    path="/password-recovery-confirm"
                    element={
                        <PublicRoute isAuth={isAuth}>
                            <PasswordRecoveryConfirmation/>
                        </PublicRoute>
                    }
                />
                <Route
                    path="/password-recovery-confirm-result"
                    element={
                        <PublicRoute isAuth={isAuth}>
                            <PasswordRecoveryConfirmationResult/>
                        </PublicRoute>
                    }
                />
            </Route>

            <Route
                index
                path="/home"
                element={
                    <PrivateRoute isAuth={isAuth}>
                        <StandardLayout children1={<Filters/>} children2={<Content/>}/>
                    </PrivateRoute>
                }
            />
            <Route
                index
                path="/home/:id"
                element={
                    <PrivateRoute isAuth={isAuth}>
                        <StandardLayout children1={<Filters/>} children2={<Details/>}/>
                    </PrivateRoute>
                }
            />
            <Route
                index
                path="/admin-panel"
                element={
                    <PrivateRoute isAuth={isAuth}>
                        <StandardLayout children1={<AdminList/>} children2={<></>}/>
                    </PrivateRoute>
                }
            />
            <Route
                index
                path="/admin-panel/add-research-project"
                element={
                    <PrivateRoute isAuth={isAuth}>
                        <StandardLayout children1={<AdminList/>} children2={<AddProject/>}/>
                    </PrivateRoute>
                }
            />
            <Route
                index
                path="/admin-panel/projects-approval"
                element={
                    <PrivateRoute isAuth={isAuth}>
                        <StandardLayout children1={<AdminList/>} children2={<ProjectsApproval/>}/>
                    </PrivateRoute>
                }
            />
            <Route
                index
                path="/admin-panel/users-managing"
                element={
                    <PrivateRoute isAuth={isAuth}>
                        <StandardLayout children1={<AdminList/>} children2={<UsersManaging/>}/>
                    </PrivateRoute>
                }
            />
            <Route
                index
                path="/admin-panel/categories-managing"
                element={
                    <PrivateRoute isAuth={isAuth}>
                        <StandardLayout children1={<AdminList/>} children2={<CategoriesManagingPage/>}/>
                    </PrivateRoute>
                }
            />
            <Route
                index
                path="/admin-panel/tags-managing"
                element={
                    <PrivateRoute isAuth={isAuth}>
                        <StandardLayout children1={<AdminList/>} children2={<TagsManaging/>}/>
                    </PrivateRoute>
                }
            />
            <Route
                index
                path="/admin-panel/projects-types-managing"
                element={
                    <PrivateRoute isAuth={isAuth}>
                        <StandardLayout children1={<AdminList/>} children2={<ProjectsTypesManaging/>}/>
                    </PrivateRoute>
                }
            />
        </Routes>
    );
};

export default App;
