import '../../../css/common/avatar.css';

import {useEffect, useState} from "react";
import {Avatar} from "antd";
import {getAvatarImage} from "./get-avatar-image";

const ShowAvatar = ({value}: any) => {
    const [avatarImage, setAvatarImage] = useState<string>("");

    useEffect(() => {
        const fetchAvatarImage = async () => {
            setAvatarImage(await getAvatarImage(value))
        }
        fetchAvatarImage();

        return (() => {
            if (avatarImage.includes("blob")) {
                URL.revokeObjectURL(avatarImage);
            }
        });
    }, [value]);

    return (
        <Avatar
            className="private-avatar-image"
            src={avatarImage}
        />
    );
};

export default ShowAvatar;