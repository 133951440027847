import "../../css/components/home/header.css";

import rcNubipLogo from "../../assets/logos/rcnubip-logo.png";

import {Link, useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useAuthStore, UserType} from "../../stores/authStore";
import {getAvatarImage} from "../common/avatar-image/get-avatar-image";
import Paragraph from "antd/es/typography/Paragraph";
import {Roles} from "../../constants/auth/userRoles";
import {SetAvatarImage} from "../common/avatar-image/set-avatar-image";
import {LogoutOutlined} from "@ant-design/icons";
import instance from "../../api/configuration/configure";
import {message} from "antd";

const Header = () => {
    const location = useLocation();
    const [avatarImageUrl, setAvatarImageUrl] = useState<string>();
    const [userImage, setUserImage] = useState<UserType>();
    const {user} = useAuthStore();
    const {setUser, setIsAuth} = useAuthStore();

    useEffect(() => {
        setUserImage(user);
    }, [user]);

    useEffect(() => {
        fetchAvatarImage();

        return (() => {
            if (typeof avatarImageUrl == 'string' && avatarImageUrl.includes("blob")) {
                URL.revokeObjectURL(avatarImageUrl);
            }
        });
    }, [userImage]);

    const fetchAvatarImage = async () => {
        setAvatarImageUrl(await getAvatarImage(userImage?.avatarImage as string));
    };

    const getHighestRole = (roles: Array<String>) => {
        if (roles.includes(Roles.ADMINISTRATOR)) {
            return Roles.ADMINISTRATOR;
        } else if (roles.includes(Roles.TEACHER)) {
            return Roles.TEACHER;
        } else if (roles.includes(Roles.STUDENT)) {
            return Roles.STUDENT;
        } else if (roles.includes(Roles.GUEST)) {
            return Roles.GUEST;
        }
    };

    const LogOutHandle = () => {
        instance.post(
            process.env.REACT_APP_SERVER_URL + "api/auth/revoke-token",
            {token: localStorage.getItem("refreshToken")}
        ).then((response) => {
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("avatarImage");

            setUser({} as UserType);
            setIsAuth(false);

            message.success(response.data.message);
        }).catch((err) => {
            message.error(err.message);
        });
    }

    return (
        <section className="header-wrapper">
            <div className="header-college-title noselect">
                <Link to="/home" className="header-college-title-link">
                    <img src={rcNubipLogo} alt="RCNUBIP Logo"/>
                    <h1 className="header-college-title-link-wrapper">Репозитарій</h1>
                </Link>
                {(user.roles.includes(Roles.ADMINISTRATOR) || user.roles.includes(Roles.TEACHER)) &&
                    <>
                        <h1>|</h1>
                        {location.pathname === "/home" ?
                            <Link to="/admin-panel" className="header-college-title-admin">
                                <h1> Адмін панель</h1>
                            </Link> :
                            <Link to="/home" className="header-college-title-admin">
                                <h1> Головна</h1>
                            </Link>
                        }

                    </>
                }
            </div>
            <div className="header-user-info-wrapper">
                <div className="header-user-info">
                    <Paragraph className="header-user-info-title-name">
                        <b>{user?.lastName + " " + user?.firstName + " " + user?.middleName}</b>
                    </Paragraph>
                    <Paragraph className="header-user-info-title-role">
                        {getHighestRole(user?.roles as String[])}
                    </Paragraph>
                </div>
                <div>
                    <input
                        id="avatarImage"
                        accept=".jpeg, .jpg, .png"
                        type="file"
                        style={{display: "none"}}
                        onChange={async (e: any) => {
                            const response = await SetAvatarImage(e.target.files[0])();
                            if (response !== null && response !== "") {
                                localStorage.setItem("avatarImage", response);
                            }
                        }}
                    />
                    <label htmlFor="avatarImage">
                        <img className="header-avatar-image noselect" src={avatarImageUrl} alt="Avatar Image"/>
                    </label>
                </div>
                <div>
                    <LogoutOutlined className="header-logout-btn" onClick={LogOutHandle} />
                </div>
            </div>
        </section>
    );
}

export default Header;
