import "../../css/components/admin-panel/categories-managing.css";

import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {useEffect, useState} from "react";
import {Button, Input, message, Popconfirm, Space, Table} from "antd";
import {DeleteOutlined, EditOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import instance from "../../api/configuration/configure";
import {useAdminPanelStore} from "../../stores/adminPanelStore";

interface FormValues {
    key: number;
    id: string;
    name: string;
}

interface CreateCategory {
    name: string;
    reCaptchaToken: string;
}

const CategoriesManagingPage = () => {
    const {executeRecaptcha} = useGoogleReCaptcha();
    const [newCategoryText, setNewCategoryText] = useState<string>("");
    const {categories, setCategories} = useAdminPanelStore();

    const onCategoryConfirmDelete = (id: string) => {
        instance.delete("api/category/" + id).then(() => {
            setCategories(categories.filter(
                (item) => item.id !== id
            ));
        })
    };

    useEffect(() => {
        instance.get("api/category").then((response) => {
            setCategories(response.data);
        }).catch(() => {
            message?.error("Помилка підгрузки списку категорій");
        });
    }, []);

    const data: Array<FormValues> = categories.map((category: any, key: any) => {
        return {
            id: category.id, name: category.name, key: key,
        };
    });

    const handleInputChange = (event: any, index: number) => {
        const {value} = event.target;

        data[index].name = value;
    };

    const handleSubmit = async (id: string) => {
        if (!executeRecaptcha) {
            return;
        }
        const reCaptchaToken = await executeRecaptcha();

        const tmp = data.find((x) => x.id === id);
        instance.put("api/category", {
            id: tmp?.id,
            name: tmp?.name,
            reCaptchaToken: reCaptchaToken
        }).then((response) => {
            message.success(response?.data.message);
        }).catch((error) => {
            message.error(error.response?.data.message);
        });
    };

    const columns = [{
        title: "Назва", dataIndex: "name", key: "name", render: (category: any, index: any) => (<div
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10px"
            }}
        >
            <div style={{
                flex: 1
            }}>
                <Input
                    placeholder="Будь ласка введіть назву спеціальності"
                    defaultValue={category}
                    onChange={(e) => handleInputChange(e, index.key)}
                />
            </div>
            <div>
                <EditOutlined
                    style={{
                        color: "grey"
                    }}
                    onClick={() => handleSubmit(index.id)}
                />
                &ensp;
                <Popconfirm
                    title="Видалення спеціальності"
                    description="Ви впевнені, що хочете видалити цю спеціальність?"
                    onConfirm={() => {
                        onCategoryConfirmDelete(categories.find((x: any) => x.name === category)?.id as string);
                    }}
                    onCancel={() => {
                    }}
                    okText="Так, звичайно"
                    cancelText="Ні"
                    icon={<QuestionCircleOutlined
                        style={{
                            color: "red"
                        }}
                    />}
                >
                    <DeleteOutlined style={{
                        color: "red"
                    }}
                    />
                </Popconfirm>
            </div>
        </div>),
    },];

    const addCategory = async () => {
        if (newCategoryText === "") {
            message.error("Пусте поле нової спеціальності");
            return;
        }

        if (!executeRecaptcha) {
            return;
        }
        const reCaptchaToken = await executeRecaptcha();

        const model = {
            name: newCategoryText, reCaptchaToken: reCaptchaToken,
        } as CreateCategory;

        instance.post("api/category", model).then((response) => {
            instance.get("api/category").then((response) => {
                setCategories(response.data);
            }).catch(() => {
                message?.error("Помилка підгрузки списку категорій");
            });
            setNewCategoryText("");
            message.success(response?.data.message);
        }).catch((error) => {
            message.error(error.response?.data.message);
        });
    }

    return (
        <div className="categories-managing-panel">
            <h2 className="update-category-title">Управління спеціальностями</h2>
            <Table
                dataSource={[...data]}
                size="small"
                columns={columns}
                pagination={false}
                footer={() => (<Space.Compact className="update-category-space-compact">
                    <Input value={newCategoryText} onChange={(e) => {
                        setNewCategoryText(e.target.value)
                    }} placeholder="Назва нової спеціальності"/>
                    <Button onClick={addCategory} className="update-category-add-category-btn" ghost> +</Button>
                </Space.Compact>)}
                bordered={true}
            />
        </div>
    );
}

export default CategoriesManagingPage;