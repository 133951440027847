import {useEffect, useState} from "react";
import {Button} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import instance from "../../../api/configuration/configure";

const GetFile = ({record}: any) => {
    const [fileSrc, setFileSrc] = useState("");

    useEffect(() => {
        let fileUrl = "";
        instance
            .get(
                `${process.env.REACT_APP_SERVER_URL}storage/file?filename=${record.fileName}`,
                {
                    responseType: "blob",
                }
            )
            .then((response: any) => {
                fileUrl = URL.createObjectURL(response.data);
                setFileSrc(fileUrl);
            }).catch();

        return (() => {
            if (fileUrl !== "") {
                URL.revokeObjectURL(fileUrl);
            }
        });
    }, [record]);

    return (
        <a href={fileSrc} download={record.name.replace(/ /g, '_') + ".pdf"}>
            <Button type="dashed" style={{width: "100%"}}><DownloadOutlined/>Завантажити проєкт</Button>
        </a>
    );
};

export default GetFile;
