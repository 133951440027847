import "../../css/components/home/filters.css";

import React, {useEffect, useState} from "react";
import {AutoComplete, Button, Form, message, Select} from "antd";
import {useAuthStore} from "../../stores/authStore";
import {useForm} from "antd/es/form/Form";
import instance from "../../api/configuration/configure";
import {SearchParams, useFiltersStore} from "../../stores/filtersStore";
import debounce from "debounce";
import {RuleObject} from "antd/es/form";
import {Project, ProjectsList, useContentStore} from "../../stores/contentStore";

const Filters = () => {
    const {user} = useAuthStore();
    const {
        searchParams,
        authorsList,
        yearsList,
        categories,
        setYearsList,
        setCategories,
        setAuthorsList,
        setSearchParams
    } = useFiltersStore();
    const {setData, page, setPage} = useContentStore();
    const [authorQuery, setAuthorQuery] = useState<string>("");
    const [tagsSearch, setTagsSearch] = useState<Array<string>>();
    const [form] = useForm();

    useEffect(() => {
        instance.get("api/research-project/years-list").then((response) => {
            setYearsList(response.data);
        }).catch(() => {
            message?.error("Помилка підгрузки списку років");
        });

        instance.get("api/category").then((response) => {
            setCategories(response.data);
        }).catch(() => {
            message?.error("Помилка підгрузки списку категорій");
        });
    }, []);

    useEffect(() => {
        let tags = null;
        if (searchParams.tags != null) {
            tags = JSON.stringify(searchParams.tags).replace("%22", '"');
        }
        instance
            .get("api/research-project", {
                params: {
                    categoryId: searchParams.categoryId,
                    pageIndex: page,
                    projectName: searchParams.query,
                    year: searchParams.year,
                    fullName: searchParams.fullName,
                    type: searchParams.type,
                    tags: tags,
                    rejectedProjects: searchParams.rejectedProjects,
                    ownProjects: searchParams.ownProjects,
                },
            })
            .then((response: any) => {
                let responseData = response.data;
                if (response.status === 204 && response.data === "") {
                    responseData = {
                        items: [] as Array<Project>,
                        totalCount: 0,
                        pageIndex: 0,
                        pageSize: 0,
                        totalPages: 0,
                        hasPreviousPage: false,
                        hasNextPage: false,
                    } as ProjectsList;
                }

                setData(responseData);
            })
            .catch(() => {
                message?.error("Помилка підгрузки списку проєктів");
            });
    }, [searchParams]);

    const debouncedSearchTags = debounce((name: string) => {
        instance.get("api/tag/search?name=" + name).then((response) => {
            setTagsSearch(response.data)
        }).catch(() => {
            message?.error("Помилка підгрузки ключових слів");
        });
    }, 500);

    const validateTags = (rule: RuleObject, value: string[], callback: (error?: string) => void) => {
        if (value && value.length > 10) {
            return Promise.reject("Максимальна кількість тегів рівна 10 одиницям.");
        } else {
            return Promise.resolve();
        }
    };

    const debouncedSearchAuthor = debounce(() => {
        if (authorQuery === "") {
            return;
        }

        instance.get(
            "api/auth/users-list?fullName=" + authorQuery
        ).then((response) => {
            setAuthorsList(response.data);
        }).catch(() => {
            message?.error("Помилка підгрузки списку користувачів");
        });
    }, 500);

    const optionsAuthor = authorsList.map((result) => ({
        value: result,
        label: result,
    }));

    const onFinish = (values: any) => {
        setPage(1);
        setSearchParams({
            year: values.year,
            categoryId: values.categoryId,
            fullName: values.fullName,
            type: values.type,
            tags: values.tags,
            rejectedProjects: values.rejectedProjects,
            ownProjects: values.ownProjects,
        } as SearchParams);
    }

    return (
        <div>
            <h2 className="noselect search-title">Пошук за параметрами</h2>
            <Form
                initialValues={{remember: true}}
                form={form}
                autoComplete="on"
                layout="vertical"
                onFinish={onFinish}
                scrollToFirstError
            >
                <Form.Item className="form-item-filters-wrapper noselect" label="Рік" name="year"
                           hasFeedback>
                    <Select
                        className="form-section-filters"
                        placeholder="Виберіть рік наукової роботи"
                        options={yearsList.map((year) => {
                            return {
                                value: year,
                                label: year,
                            };
                        })}
                    />
                </Form.Item>
                <Form.Item
                    className="form-item-filters-wrapper noselect"
                    label="Автор наукової роботи"
                    name="fullName"
                    hasFeedback
                >
                    <AutoComplete
                        options={optionsAuthor}
                        onSearch={debouncedSearchAuthor}
                        onChange={(value) => {
                            setAuthorQuery(value);
                        }}
                        placeholder="Введіть автора наукової роботи"
                        className="form-section-filters"
                        allowClear
                    />
                </Form.Item>
                <Form.Item
                    className="form-item-filters-wrapper noselect"
                    label="Спеціальність наукової роботи"
                    name="categoryId"
                    hasFeedback
                >
                    <Select
                        className="form-section-filters"
                        placeholder="Введіть спеціальність наукової роботи"
                        options={categories.map((category) => {
                            return {
                                value: category.id,
                                label: category.name,
                            };
                        })}
                    />
                </Form.Item>
                <Form.Item className="form-item-filters-wrapper noselect" name="type"
                           label="Тип наукового проєкту" hasFeedback>
                    <Select
                        className="form-section-filters"
                        placeholder="Виберіть тип роботи наукового проєкт"
                        options={[
                            {value: "0", label: "Дипломна робота"},
                            {value: "1", label: "Курсова робота"},
                            {value: "2", label: "Інше"},
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    name="tags"
                    label="Ключові слова наукового проєкту"
                    rules={[
                        {
                            validator: validateTags,
                        }
                    ]}
                    className="form-item-filters-wrapper noselect"
                    hasFeedback
                >
                    <Select
                        mode="tags"
                        className="form-section-filters"
                        placeholder="Виберіть набір ключових слів"
                        onSearch={(value: string) => {
                            debouncedSearchTags(value);
                        }}
                        maxTagCount={10}
                    >
                        {tagsSearch?.map((tag, key) => (
                            <Select.Option key={key} value={tag}>
                                {tag}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                {(user?.roles.includes("Викладач") ||
                    user?.roles.includes("Адміністратор")) && (
                    <Form.Item
                        name="rejectedProjects"
                        label="Тип верифікації"
                        className="form-item-filters-wrapper noselect"
                        hasFeedback
                    >
                        <Select
                            className="form-section-filters"
                            placeholder="Виберіть тип верифікації наукового проєкт"
                            options={[
                                {value: false, label: "Затверджені наукові проєкти"},
                                {value: true, label: "Відхилені наукові проєкти"},
                            ]}
                        />
                    </Form.Item>)}
                {!user?.roles.includes("Гість") && (
                    <Form.Item
                        name="ownProjects"
                        label="Приналежність наукового проєкту"
                        className="form-item-filters-wrapper noselect"
                        hasFeedback
                    >
                        <Select
                            className="form-section-filters"
                            placeholder="Виберіть приналежність наукового проєкту"
                            options={[
                                {value: false, label: "Всі наукові проєкти"},
                                {value: true, label: "Ваші наукові проєкти"},
                            ]}
                        />
                    </Form.Item>)}
                <Form.Item className="form-item-filters-wrapper noselect">
                    <Button type="primary" className="form-btn-filters" htmlType="submit">
                        Зберегти
                    </Button>
                </Form.Item>
            </Form>
            <Button
                type="primary"
                onClick={() => {
                    setSearchParams({
                        query: null,
                        pageIndex: 1,
                        year: null,
                        categoryId: null,
                        fullName: null,
                        type: null,
                        tags: [],
                        rejectedProjects: "",
                        ownProjects: false,
                    } as SearchParams);
                    form.resetFields();
                }}
                className="form-btn-filters"
                htmlType="button"
                ghost
            >
                Очистити параметри пошуку
            </Button>
        </div>
    );
}

export default Filters;
