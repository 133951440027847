import React from "react";

const ProjectsTypeManaging: React.FC = () => {
    return (
        <h1 style={{textAlign: "center"}}>
            Скоро. В розробці
        </h1>
    )
}

export default ProjectsTypeManaging;