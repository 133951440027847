import "../../css/components/admin-panel/users-managing.css";

import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {useEffect, useState} from "react";
import debounce from "debounce";
import {ColumnsType} from "antd/es/table";
import {AutoComplete, Button, Input, message, Select, Table} from "antd";
import instance from "../../api/configuration/configure";
import axios from "axios";
import ShowAvatar from "../common/avatar-image/avatar";
import {SearchOutlined} from "@ant-design/icons";
import {useAdminPanelStore, UsersManaging} from "../../stores/adminPanelStore";

interface FormValues {
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
    avatar: string;
    currentRole: string;
}

const UsersManagingPage = () => {
    const {executeRecaptcha} = useGoogleReCaptcha();
    const {usersManaging, rolesList, setUsersManaging, setRolesList} = useAdminPanelStore();

    const [query, setQuery] = useState<string>("");
    const [loaded, setLoaded] = useState<boolean>(false);

    let data: Array<FormValues> = usersManaging.items.map((user: any) => {
        return {
            id: user.id,
            firstName: user.firstName,
            middleName: user.middleName,
            lastName: user.lastName,
            avatar: user.avatar,
            currentRole: user.currentRole,
        };
    });

    useEffect(() => {
        setLoaded(false);
        instance.get("api/user/get-users-info?page=1&query=").then((response) => {
            setUsersManaging(response.data);
        }).catch(() => {
            message?.error("Щось пішло не так");
        });

        instance.get("api/auth/roles-list").then((response) => {
            setRolesList(response.data);
        }).catch(() => {
            message?.error("Щось пішло не так");
        });
        setLoaded(true);
    }, []);

    useEffect(() => {
        if (loaded) {
            debouncedSearchUsers();
        }
    }, [query]);

    const debouncedSearchUsers = debounce(() => {
        instance.get("api/user/get-users-info?page=1&query=" + query).then((response) => {
            setUsersManaging(response.data);
        }).catch(() => {
            message?.error("Щось пішло не так");
        });
    }, 800);

    const handleInputLastNameChange = (event: any, index: number) => {
        const {value} = event.target;

        data[index].lastName = value;
    };

    const handleInputFirstNameChange = (event: any, index: number) => {
        const {value} = event.target;

        data[index].firstName = value;
    };

    const handleInputMiddleNameChange = (event: any, index: number) => {
        const {value} = event.target;

        data[index].middleName = value;
    };

    const columns: ColumnsType<UsersManaging> = [
        {
            title: "Прізвище",
            dataIndex: "lastName",
            key: "lastName",
            width: "15%",
            render: (record: any, index: any) =>
                <Input placeholder="Введіть прізвище користувача" defaultValue={record}
                       onChange={(e) => handleInputLastNameChange(e, index.key)}/>
        },
        {
            title: "Ім'я",
            dataIndex: "firstName",
            key: "firstName",
            width: "15%",
            render: (record: any, index: any) =>
                <Input placeholder="Введіть ім'я користувача" defaultValue={record}
                       onChange={(e) => handleInputFirstNameChange(e, index.key)}/>
        },
        {
            title: "По батькові",
            dataIndex: "middleName",
            key: "middleName",
            width: "15%",
            render: (record: any, index: any) =>
                <Input placeholder="Введіть по батькові користувача" defaultValue={record}
                       onChange={(e) => handleInputMiddleNameChange(e, index.key)}/>
        },
        {
            title: "Функції",
            dataIndex: "id",
            key: "id",
            render: (record: any, index: any) => (
                <div className="users-manage-list-table-column-key">
                    <Button type="primary" onClick={async () => {
                        if (!executeRecaptcha) {
                            return;
                        }
                        const reCaptchaToken = await executeRecaptcha();

                        const tmp = data.find((x) => x.id === record);
                        const model = {
                            firstName: tmp?.firstName,
                            middleName: tmp?.middleName,
                            lastName: tmp?.lastName,
                            reCaptchaToken: reCaptchaToken,
                        };

                        try {
                            const response = await instance.put("api/user?id=" + tmp?.id, model);

                            message.success(response?.data.message);
                        } catch (err: any) {
                            if (axios.isAxiosError(err)) {
                                message.error(err.response?.data.message);
                            }
                        }
                    }} ghost>Зберегти</Button>
                    <Button type="primary" onClick={async () => {
                        try {
                            const response = await instance.delete("api/user/clear-avatar-image?id=" + record);

                            message.success(response?.data.message);
                        } catch (err: any) {
                            message.error(err.response?.data.message);
                        }
                    }} ghost>Видалити аватар</Button>
                </div>
            ),
        },
        {
            title: "Зміна прав доступу",
            dataIndex: "currentRole",
            key: "currentRole",
            render: (record: any, index: any) => (
                <div className="users-manage-list-table-column-current-role">
                    <Select
                        className="users-manage-list-table-column-current-role-select"
                        placeholder="Виберіть роль"
                        defaultValue={record}
                        options={rolesList.map((role: any) => {
                            return {
                                value: role,
                                label: role,
                            };
                        })}
                        onChange={(value: string) => {
                            data[index.key].currentRole = value;
                        }}
                    />
                    <Button type="primary" onClick={async () => {
                        if (!executeRecaptcha) {
                            return;
                        }
                        const reCaptchaToken = await executeRecaptcha();

                        const model = {
                            id: data[index.key].id,
                            role: data[index.key].currentRole,
                            reCaptchaToken: reCaptchaToken,
                        };

                        try {
                            const response = await instance.post("api/auth/set-user-role", model);

                            message.success(response?.data.message);
                        } catch (err: any) {
                            if (axios.isAxiosError(err)) {
                                message.error(err.response?.data.message);
                            }
                        }
                    }} ghost>Надати роль</Button>
                </div>
            ),
        },
    ];

    const handleTableChange = async (pagination: any) => {
        instance.get("api/user/get-users-info?page=" + pagination.current + "&query=" + query).then((response) => {
            setUsersManaging(response.data);
        }).catch(() => {
            message?.error("Щось пішло не так");
        });
    };

    return (
        <div className="users-managing-panel">
            <h2 className="users-manage-list-title">Управління користувачами</h2>
            <div className="users-managing-wrapper">
                <AutoComplete
                    onChange={(value) => setQuery(value)}
                    className="users-manage-list-table-header-search-field"
                    allowClear
                >
                    <Input prefix={<SearchOutlined/>} placeholder="Введіть ПІБ користувача"/>
                </AutoComplete>
                <Table
                    size="small"
                    columns={columns}
                    dataSource={usersManaging.items.map((user: any, key: any) => {
                        return {
                            ...user,
                            key: key,
                        };
                    })}
                    rowKey="id"
                    onChange={handleTableChange}
                    pagination={{
                        current: usersManaging.pageIndex,
                        pageSize: usersManaging.pageSize,
                        total: usersManaging.totalCount,
                        pageSizeOptions: [],
                    }}
                    expandable={{
                        expandedRowRender: (record, index) => (
                            <>
                                <div className="users-manage-list-table-expandable-item">
                                    <p className="users-manage-list-table-expandable-item-title">Повна інформація:</p>
                                    <span><ShowAvatar value={record.avatar}/></span>
                                    <span><b>{record.lastName}</b></span>
                                    <span><b>{record.firstName}</b></span>
                                    <span><b>{record.middleName}</b>,</span>
                                    <span>{record.email}</span>
                                </div>
                            </>
                        ),
                    }}
                    bordered
                />
            </div>
        </div>
    );
};

export default UsersManagingPage;
