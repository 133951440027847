import "../../css/components/admin-panel/add-project.css";
import "../../css/components/style/quill.snow.css";

import {Button, Form, Input, message, Select} from "antd";
import {useForm} from "antd/es/form/Form";
import ReactQuill from "react-quill";
import {FileDoneOutlined} from "@ant-design/icons";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {useEffect, useState} from "react";
import instance from "../../api/configuration/configure";
import debounce from "debounce";
import {useFiltersStore} from "../../stores/filtersStore";
import {useAdminPanelStore} from "../../stores/adminPanelStore";
import {useMediaQuery} from "react-responsive";

export interface ReactQuillType {
    text: string;
    length: number;
}

const AddProject = () => {
    const desktopXtablet = useMediaQuery({minWidth: 768});

    const [form] = useForm();
    const {executeRecaptcha} = useGoogleReCaptcha();
    const [tagsSearch, setTagsSearch] = useState<Array<string>>();
    const [description, setDescription] = useState<ReactQuillType>({
        text: "",
        length: -2,
    } as ReactQuillType);
    const {
        categories,
        setCategories,
    } = useFiltersStore();
    const {createProjectId, setCreateProjectId} = useAdminPanelStore();

    useEffect(() => {
        if (categories == null) {
            instance.get("api/category").then((response) => {
                setCategories(response.data);
            }).catch(() => {
                message?.error("Помилка підгрузки списку категорій");
            });
        }
    }, []);

    const onFinish = async (values: any) => {
        if (!executeRecaptcha) {
            return;
        }
        const reCaptchaToken = await executeRecaptcha();

        instance.post("api/research-project", {
            id: createProjectId,
            name: values.name,
            description: values.description,
            categoryName: values.categoryName,
            type: values.type,
            tags: values.tags,
            reCaptchaToken: reCaptchaToken,
        }).then((response) => {
            setTimeout(() => {
                setDescription({
                    text: "",
                    length: -2,
                } as ReactQuillType);
                setTagsSearch([]);
                form.resetFields();
                setCreateProjectId("");
            }, 500);

            message.success(response?.data.message);
        }).catch((error) => {
            message.error(error.response?.data.message);
        });
    }

    const fileHandler = async (e: any) => {
        const selectedFile = e.target.files[0];

        if (selectedFile) {
            const fileSizeInBytes = selectedFile.size;

            if (fileSizeInBytes > 8388608) {
                message?.error('Максимально допустимий ліміт файлу 8 мб');
                e.target.value = '';
                return;
            }

            const formData = new FormData();

            formData.append("id", createProjectId);
            formData.append("file", selectedFile);

            const response = await instance.post(
                "api/research-project/file-load",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        withCredentials: true,
                    },
                }
            );

            setCreateProjectId(response.data.id);
        }
    };

    const debouncedSearchTags = debounce(async (name: string) => {
        if (name === "") {
            return;
        }

        await instance
            .get("api/tag/search?name=" + name)
            .then((response: any) => setTagsSearch(response.data));
    }, 500);

    const validateTags = (rule: any, value: any) => {
        if (value && value.length > 10) {
            return Promise.reject("Максимальна кількість тегів рівна 10 одиницям.");
        } else if (value && value.length < 3) {
            return Promise.reject("Мінімальна кількість тегів рівна 3 одиницям.");
        } else {
            return Promise.resolve();
        }
    };

    const validateFile = (rule: any, value: any) => {
        if (createProjectId === "") {
            return Promise.reject("Завантажте файл наукового проєкту!");
        } else {
            return Promise.resolve();
        }
    };

    const validateReactQuill = (rule: any, value: any) => {
        if (description?.length === -2) {
            return Promise.resolve();
        }

        if (description?.length === 0) {
            return Promise.reject("Введіть опис до наукового проєкту!");
        }

        if (description?.length > 800) {
            return Promise.reject("Максимальна довжина назви проєкту 800 символів!");
        } else if (description?.length < 3) {
            return Promise.reject("Мінімальна довжина назви проєкту три символа!");
        }

        return Promise.resolve();
    };

    const reactQuillOnChange = (value: string, delta: any, source: any, editor: any) => {
        setDescription({
            text: value,
            length: editor.getText().length - 1,
        } as ReactQuillType);
    }

    let toolbarOptions = [
        [{'header': [1, 2, 3, 4, 5, 6, false]}],

        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons

        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
        [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
        [{'direction': 'rtl'}],                         // text direction

        ['blockquote', 'code-block', 'link'],
        [{'color': []}, {'background': []}],          // dropdown with defaults from theme
        [{'align': []}],

        ['clean']                                         // remove formatting button
    ];

    return (
        <section className="add-research-project-wrapper">
            <Form
                initialValues={{remember: true}}
                autoComplete="on"
                onFinish={onFinish}
                form={form}
                layout="vertical"
                scrollToFirstError
            >
                <h2 className="form-title-create-research-project">Додати науковий проєкт</h2>
                <Form.Item
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Введіть назву наукового проєкту!",
                        },
                        {
                            max: 100,
                            message: "Максимальна довжина назви проєкту 100 символів!",
                        },
                        {
                            min: 3,
                            message: "Мінімальна довжина назви проєкту три символа!",
                        },
                    ]}
                    label="Назва"
                    className="form-create-research-project-item"
                    hasFeedback
                >
                    <Input
                        maxLength={100}
                        className="form-create-research-project-input"
                        placeholder="Введіть назву наукового проєкту"
                    />
                </Form.Item>
                <Form.Item
                    name="description"
                    rules={[
                        {
                            required: true,
                            validator: validateReactQuill,
                        },
                    ]}
                    label="Опис"
                    className="form-create-research-project-item"
                    hasFeedback
                >
                    <ReactQuill theme="snow" placeholder="Введіть опис наукового проєкту"
                                className="form-create-research-project-react-quill"
                                onChange={reactQuillOnChange}
                                modules={{
                                    toolbar: toolbarOptions,
                                }}
                    />
                </Form.Item>
                <Form.Item
                    name="categoryName"
                    rules={[
                        {
                            required: true,
                            message: "Виберіть спеціальність наукового проєкту!",
                        },
                    ]}
                    label="Спеціальність"
                    className="form-create-research-project-item"
                    hasFeedback
                >
                    <Select
                        placeholder="Виберіть спеціальність наукового проєкту"
                        size={desktopXtablet ? "middle" : "large"}
                        options={categories.map((item: any) => ({
                            value: item.name,
                            label: item.name,
                        }))}
                    />
                </Form.Item>
                <Form.Item
                    name="type"
                    rules={[
                        {
                            required: true,
                            message: "Виберіть тип наукового проєкту!",
                        },
                    ]}
                    label="Тип"
                    className="form-create-research-project-item"
                    hasFeedback
                >
                    <Select
                        placeholder="Виберіть тип роботи наукового проєкту"
                        size={desktopXtablet ? "middle" : "large"}
                        options={[
                            {value: "0", label: "Дипломна робота"},
                            {value: "1", label: "Курсова робота"},
                            {value: "2", label: "Інше"},
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    name="tags"
                    rules={[
                        {
                            required: true,
                            message: "Виберіть набір ключових слів!",
                        },
                        {
                            validator: validateTags,
                        },
                    ]}
                    label="Ключові слова"
                    className="form-create-research-project-item"
                    hasFeedback
                >
                    <Select
                        mode="tags"
                        placeholder="Введіть набір ключових слів"
                        size={desktopXtablet ? "middle" : "large"}
                        onSearch={(value: string) => {
                            debouncedSearchTags(value);
                        }}
                        maxTagCount={10}
                    >
                        {tagsSearch?.map((tag, key) => (
                            <Select.Option key={key.toString()} value={tag}>
                                {tag}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="upload-file"
                    rules={[
                        {
                            required: true,
                            validator: validateFile,
                        },
                    ]}
                    hasFeedback>
                    <div className="form-create-research-project-load-data-wrapper-item">
                        <input
                            id="file"
                            accept=".pdf"
                            type="file"
                            style={{display: "none"}}
                            onChange={fileHandler}
                        />
                        <label htmlFor="file">
                            <div className="form-create-research-project-load">
                                {createProjectId !== "" ? (
                                    <div className="form-create-research-project-file">
                                        <FileDoneOutlined className="form-create-research-project-file-icon"/>
                                        <span className="form-create-research-project-file-text">Файл успішно завантажено!</span>
                                    </div>) : (
                                    <div><p className="form-create-research-project-load-text">Завантажити файл</p>
                                    </div>)}
                            </div>
                        </label>
                    </div>
                </Form.Item>
                <Form.Item className="form-create-research-project-item">
                    <Button type="primary" className="form-create-research-project-btn" htmlType="submit">
                        Додати
                    </Button>
                </Form.Item>
            </Form>
        </section>
    );
}

export default AddProject;
