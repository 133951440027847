import {create} from "zustand/react";

export interface StatisticsProps {
    totalCategoriesCount: number;
    totalUsersCount: number;
    totalVerifiedResearchProjectsCount: number;
    totalInApprovalResearchProjectsCount: number;
}

type LandingStore = {
    statistics: StatisticsProps;
    setStatistics: (statistics: StatisticsProps) => void;
}

export const useLandingStore = create<LandingStore>()(
    (set) => ({
        statistics: {
            totalCategoriesCount: 0,
            totalUsersCount: 0,
            totalVerifiedResearchProjectsCount: 0,
            totalInApprovalResearchProjectsCount: 0
        },
        setStatistics: (statistics: StatisticsProps) => set(() => ({statistics: statistics}))
    })
);
